import {useMediaListStore} from "~/stores/media-list";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const mediaListStore = useMediaListStore()
    const id = getSingleValue(to.params.id)

    if (stringIsInteger(id)) {
        const nodeData = await mediaListStore.getNode(Number(to.params.id))
        if (!nodeData) {
            abortNavigation(createError({ statusCode: 404, statusMessage: `Could not find node with ID ${to.params.id}` }))
        }
    } else {
        abortNavigation(createError({ statusCode: 404, statusMessage: `Invalid node ID` }))
    }

})
